<template>
  <v-app>
    <div
      v-bind:class="{
        bgSvg: !$vuetify.theme.dark,
        bgSvgDark: $vuetify.theme.dark
      }"
    >
      <v-flex
        class="backDropper pt-3 pb-5 px-4"
        v-bind:style="{
          'color:rgb(255, 255, 255,0.5)': !$vuetify.theme.dark,
          'color:rgb(0,0,0)': $vuetify.theme.dark
        }"
      >
        <v-dialog v-model="progressDialog" persistent width="50%">
          <v-card class="text-center pa-6 pt-2 ma-0 .text-xs-h1"
            >{{ progress }}% Done... {{ progressMessage }}
            <v-progress-linear :value="progress" height="5"> </v-progress-linear
          ></v-card>
        </v-dialog>
        <v-card elevation="6" background-color="" class="pt-3 mt-1 pb-5">
          <div v-if="floatBtnControl && tempGroup">
            <v-tooltip bottom class="mb-15">
              <template v-slot:activator="{ attrs, on }">
                <v-btn
                  color="pink"
                  fab
                  dark
                  v-bind="attrs"
                  v-on="on"
                  absolute
                  bottom
                  style="bottom: 8% !important; right: 3% !important"
                  right
                  elevation="12"
                  v-on:click="sendSMS(tempGroup, 'Group')"
                  ><v-icon>fas fa-envelope</v-icon></v-btn
                ></template
              >
              <span>Group zawng zawng a message thawnna</span>
            </v-tooltip>
          </div>

          <v-tabs
            v-model="tab"
            color="primary"
            centered
            icons-and-text
            dense
            background-opacity="100"
            class=""
          >
            <v-tab align-with-title style="overflow:show">
              <h5 class="mt-1 pb-n3">Users Lists</h5>
              <v-badge
                bordered
                top
                color="red"
                :value="totalUser.length"
                class="mt-2"
                :content="totalUser.length"
                ><v-icon>fas fa-user</v-icon>
              </v-badge>
            </v-tab>

            <v-tab v-if="['SMMU', 'CMMU'].includes(userInfo.nulm)">
              <h5 class="mt-1 pb-n3">
                Pending Approval
              </h5>
              <v-badge
                bordered
                top
                color="red"
                :value="userListFinalPending.length"
                class="mt-2"
                :content="userListFinalPending.length"
              >
                <v-icon>fas fa-user-plus</v-icon></v-badge
              >
            </v-tab>
          </v-tabs>

          <v-tabs-items v-model="tab" class="pt-n12  " dense>
            <v-tab-item class="pt-n12">
              <!-- User tab--------------------------------------------------------------------------------- -->

              <v-card class="elevation-0 pt-3 mx-5 accent" min-height="69vh">
                <validation-observer v-slot="errors" ref="Observer">
                  <v-form ref="form">
                    <v-row align-content="center" dense>
                      <v-col cols="6" md="3" justify="center" align="center">
                        <validation-provider
                          v-slot="{ errors }"
                          rules="ngaiNgeiNgei"
                          name="User District"
                        >
                          <v-select
                            outlined
                            dense
                            :readonly="userInfo.nulm != 'SMMU'"
                            class="pl-4 white--text"
                            label="Ditrict of User"
                            :items="District"
                            :error-messages="errors"
                            v-model="userDistrict"
                          ></v-select>
                        </validation-provider>
                      </v-col>

                      <v-col
                        cols="6"
                        md="3"
                        justify="center"
                        align="center"
                        v-if="['SMMU', 'CMMU'].includes(userInfo.nulm)"
                      >
                        <validation-provider
                          name="Nulm"
                          rules="ngaiNgeiNgei"
                          v-slot="{ errors }"
                          v-if="['SMMU', 'CMMU'].includes(userInfo.nulm)"
                        >
                          <v-select
                            outlined
                            dense
                            label="DAY-NULM Category of User"
                            :items="NULM"
                            class="pl-4 white--text"
                            v-model="userNulm"
                            :error-messages="errors"
                            @change="nulmCheck(userNulm)"
                            :readonly="
                              !['SMMU', 'CMMU'].includes(userInfo.nulm)
                            "
                          ></v-select>
                        </validation-provider>
                      </v-col>
                      <v-col
                        justify="center"
                        align="center"
                        v-if="deptCheck"
                        cols="6"
                        md="3"
                      >
                        <validation-provider
                          v-slot="{ errors }"
                          rules="ngaiNgeiNgei"
                          name="Department"
                        >
                          <v-select
                            outlined
                            dense
                            class="pl-4"
                            label="Department"
                            :items="userType"
                            :error-messages="errors"
                            v-model="userDepartment"
                          ></v-select>
                        </validation-provider>
                      </v-col>

                      <v-col cols="6" md="3">
                        <v-select
                          outlined
                          class="pl-4"
                          v-model="search"
                          label="Search Input"
                          dense
                          :items="['Kehdarh', 'Nung Tha Mek']"
                          v-if="searchBy === 'Group Status'"
                        ></v-select>
                        <v-text-field
                          dense
                          v-if="searchBy !== 'Group Status'"
                          outlined
                          class="pl-4"
                          v-model="search"
                          label="Search Input"
                          hint="Dah ruah chuan department pumpui zawnna"
                        >
                        </v-text-field
                      ></v-col>
                      <v-col v-if="['SM&ID', 'SUH'].includes(userNulm)"></v-col>

                      <v-col v-if="search" cols="6" md="3" class="text-end">
                        <validation-provider
                          v-slot="{ errors }"
                          rules="ngaiNgeiNgei"
                          name="User Department"
                        >
                          <v-select
                            outlined
                            @change="searchFilter()"
                            :error-messages="errors"
                            dense
                            :items="['Name', 'Group Code', 'Group Status']"
                            color="warning"
                            v-model="searchBy"
                            class="pl-4 white--text"
                            label="Search By"
                          ></v-select>
                        </validation-provider>
                      </v-col>
                      <v-col
                        v-if="search && !['SM&ID', 'SUH'].includes(userNulm)"
                      ></v-col>
                      <v-col cols="6" md="3" class="text-center ma-0 pa-0 mb-5">
                        <v-btn
                          style="position:relative;left:0;top:15%"
                          :disabled="errors.invalid"
                          color="primary"
                          class="buttonGlass mt-0"
                          v-on:click="searchByUser"
                          >Search</v-btn
                        >
                      </v-col>
                    </v-row>
                    <div class="tableData">
                      <v-data-table
                        class="row-pointer accent px-4"
                        :items="userListFinal"
                        :headers="headers"
                        :calculate-widths="true"
                        @click:row="displayUser"
                        :disable-pagination="true"
                        :hide-default-footer="true"
                        :loading="loading"
                        height="49vh"
                      >
                      </v-data-table>

                      <v-pagination
                        flat
                        class="pb-3 elevation-0 "
                        v-if="!userSearchBool"
                        elevation="0"
                        @input="next"
                        v-model="pages"
                        :length="lengthUser"
                      ></v-pagination>
                      <div class="text-center mb-5" v-if="userSearchBool">
                        <v-btn
                          class="buttonGlass"
                          color="primary"
                          v-on:click="userSearchBool = !userSearchBool"
                          >Reset Search</v-btn
                        >
                      </div>
                    </div>
                  </v-form>
                </validation-observer>
              </v-card>
            </v-tab-item>
            <!-- Approval List ================================================================================-->
            <v-tab-item class="pt-n7">
              <v-card class="elevation-0 mx-5 accent" min-height="59vh">
                <div class="tableData">
                  <v-data-table
                    style="{min-height:100%}"
                    class="row-pointer accent px-4"
                    :items="userListFinalPending"
                    :headers="headers"
                    :calculate-widths="true"
                    :search="searchTable"
                    @click:row="displayUser"
                    :items-per-page="5"
                    height="49vh"
                  >
                    <template v-slot:top>
                      <v-row>
                        <v-col cols="12" md="3" align-self="center">
                          <div class="text-center pt-4">
                            <v-text-field
                              outlined
                              dense
                              min-width="50vw"
                              v-model="searchTable"
                              append-icon="fa-magnify"
                              label="Enter keyword"
                              class="mx-4"
                            ></v-text-field></div
                        ></v-col>
                      </v-row>
                    </template>
                  </v-data-table>
                </div>
              </v-card>
            </v-tab-item>
          </v-tabs-items>
        </v-card>

        <v-dialog
          :fullscreen="$vuetify.breakpoint.mobile"
          v-model="userDialog"
          max-width="70vw"
          class="mt-5 ma-0 pa-0"
        >
          <v-card class="pa-10">
            <v-btn
              class="mt-2 mr-10 elevation-0 error"
              absolute
              icon
              right
              dark
              rounded
              @click.prevent="userDialog = !userDialog"
            >
              <v-icon>fas fa-close</v-icon>
            </v-btn>
            <div class="tableData pa-6">
              <v-row
                width="50%"
                justify="center"
                :class="{ 'pt-8 ma-0 px-5': $vuetify.breakpoint.mobile }"
                class=""
              >
                <h2 class="my-5"><u>User Details</u></h2></v-row
              >

              <v-row :class="{ 'pt-5 ma-0 px-5': $vuetify.breakpoint.mobile }">
                <v-col
                  v-for="(value, index) in userTemp"
                  v-bind:key="index"
                  cols="6"
                  ><div
                    v-if="!value.toString().includes('Id')"
                    class="text-start"
                  >
                    {{ value }}
                  </div>
                </v-col>
              </v-row>

              <v-row v-if="tab < 2" justify="center">
                <v-col cols="12" v-if="reportList">
                  <v-divider class="my-5 primary"></v-divider>
                  <div class="text-center">
                    <h3>
                      <u>Report Details</u>
                    </h3>
                  </div>
                </v-col>
                <v-col cols="12">
                  <div class="text-start">
                    <v-data-table
                      v-if="reportList"
                      :items="reportList"
                      :headers="headersReports"
                      hide-default-footer
                    >
                      <template v-slot:body="{ items }">
                        <tbody>
                          <tr v-for="item in items" :key="item.name">
                            <td>{{ item.dateOfSubmission }}</td>
                            <td>{{ tsTodate(item.createdAt) }}</td>
                            <td>{{ item.reportType }}</td>
                            <td>{{ item.approvedBy }}</td>
                          </tr>
                        </tbody>
                      </template>
                    </v-data-table>
                  </div>
                </v-col>

                <v-col cols="12" v-if="attachmentList">
                  <v-divider class="my-5 primary"></v-divider>
                  <div class="text-center">
                    <h3>
                      <u>Attachment Lists</u>
                    </h3>
                  </div>
                </v-col>

                <v-col cols="12">
                  <v-list>
                    <v-list-group
                      v-for="(attach, index) in attachmentList"
                      :value="false"
                      :key="index"
                      sub-group
                      no-action
                    >
                      <template v-slot:activator>
                        <v-list-item-content>
                          <v-list-item-title
                            ><h5>
                              {{ index + 1 }}. {{ attach.name }}
                            </h5></v-list-item-title
                          >
                        </v-list-item-content>
                      </template>
                      <v-list-item
                        v-for="(attached, i) in attach.url"
                        :key="i"
                        :href="attached"
                        target="_blank"
                        class="divider-con"
                      >
                        <v-list-item-subtitle
                          style="position:relative !important; left:7% !important"
                          class=""
                        >
                          Attachment {{ i + 1 }}
                        </v-list-item-subtitle>
                      </v-list-item>
                    </v-list-group>
                  </v-list></v-col
                >

                <v-col class="text-center" v-if="tab == 1">
                  <v-btn
                    class="buttonGlass"
                    color="primary"
                    v-on:click="approveUser(id)"
                  >
                    Approve
                    <v-icon class="ml-2" dense small> fas fa-check</v-icon>
                  </v-btn>
                </v-col>
                <v-col class="text-center" v-if="tab == 1">
                  <v-btn
                    class="buttonGlass white--text"
                    color="red"
                    v-on:click="deleteUser(id)"
                  >
                    Reject
                    <v-icon class="ml-2" dense small> fas fa-times</v-icon>
                  </v-btn></v-col
                >
                <v-col class="text-center" v-if="tab == 0">
                  <v-btn
                    class="buttonGlass white--text"
                    color="red"
                    v-on:click="deleteUser(id)"
                  >
                    Delete
                    <v-icon class="ml-2" dense small> fas fa-times</v-icon>
                  </v-btn></v-col
                >
                <v-col class="text-center">
                  <v-btn
                    class="buttonGlass white--text"
                    color="primary"
                    v-on:click="sendSMS(userDetailsRaw, 'Single')"
                  >
                    Send SMS
                    <v-icon class="ml-2" dense small>
                      fas fa-envelope-open-text</v-icon
                    >
                  </v-btn></v-col
                >
              </v-row>
            </div>
          </v-card>
        </v-dialog>

        <!-- <v-dialog
          v-model="userNotification"
          max-width="600"
          transition="fab-transition"
          overlay-color=""
          overlay-opacity="50%"
          class="mt-5"
        >
          <v-card class="px-4">
            <div class="d-flex justify-end">
              <v-btn
                text
                rounded
                v-on:click="userNotification = false"
                class="d-flex align-end"
              >
                <v-icon>fas fa-chevron-circle-left</v-icon>
              </v-btn>
            </div>

            <v-card-title> Sending Message to </v-card-title>

            <v-text-field
              prepend-icon="fas fa-envelope-open-text"
              hint="Enter your message"
            >
            </v-text-field>
            <div class="d-flex justify-center">
              <v-btn text class="my-4" rounded v-on:click="sendNoti(id)">
                submit
              </v-btn>
            </div>
          </v-card>
        </v-dialog> -->
      </v-flex>
    </div>
  </v-app>
</template>
<script>
import { required } from 'vee-validate/dist/rules'
import {
  extend,
  ValidationObserver,
  ValidationProvider,
  setInteractionMode
} from 'vee-validate'
setInteractionMode('eager')
import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import axios from 'axios'
import { mapState } from 'vuex'
extend('ngaiNgeiNgei', {
  ...required,
  message: '{_field_} a ruak theilo'
})
export default {
  components: {
    ValidationProvider,
    ValidationObserver
  },
  data () {
    return {
      tempGroup: '',
      sendToGroup: false,
      userDetailsRaw: {},
      userNotification: false,
      attachmentList: undefined,
      progress: '0',
      progressMessage: 'Kan kalnaah kan kal ang',
      progressDialog: false,
      groupId: {
        smmu: 1206786,
        cmmu: 1206787,
        alf_aizawl: 1204664,
        alf_champhai: 1204672,
        alf_hnahthial: 1204673,
        alf_khawzawl: 1204674,
        alf_kolasib: 1204667,
        alf_lawngtlai: 1204666,
        alf_lunglei: 1204668,
        alf_mamit: 1204669,
        alf_saiha: 1204670,
        alf_saitual: 1204675,
        alf_serchhip: 1204671,
        clf_aizawl: 1204677,
        clf_champhai: 1204685,
        clf_hnahthial: 1204684,
        clf_khawzawl: 1204686,
        clf_kolasib: 1204678,
        clf_lawngtlai: 1204679,
        clf_lunglei: 1204680,
        clf_mamit: 1204681,
        clf_saiha: 1204682,
        clf_saitual: 1204687,
        clf_serchhip: 1204683,
        estp_aizawl: 1204710,
        estp_champhai: 1204718,
        estp_hnahthial: 1204717,
        estp_khawzawl: 1204719,
        estp_kolasib: 1204711,
        estp_lawngtlai: 1204712,
        estp_lunglei: 1204713,
        estp_mamit: 1204714,
        estp_saiha: 1204715,
        estp_saitual: 1204720,
        estp_serchhip: 1204716,
        sep_aizawl: 1204721,
        sep_champhai: 1204728,
        sep_hnahthial: 1204729,
        sep_khawzawl: 1204730,
        sep_kolasib: 1204722,
        sep_lawngtlai: 1204723,
        sep_lunglei: 1204724,
        sep_mamit: 1204725,
        sep_saiha: 1204726,
        sep_saitual: 1204731,
        sep_serchhip: 1204727,
        shg_aizawl: 1204655,
        shg_champhai: 1204662,
        shg_hnahthial: 1204676,
        shg_kolasib: 1204657,
        shg_lawngtlai: 1204658,
        shg_lunglei: 1204659,
        shg_mamit: 1204656,
        shg_saiha: 1204660,
        shg_saitual: 1204665,
        shg_serchhip: 1204661,
        sma_aizawl: 1204688,
        sma_champhai: 1204696,
        sma_hnahthial: 1204695,
        sma_khawzawl: 1204697,
        sma_lawngtlai: 1204690,
        sma_lunglei: 1204691,
        sma_mamit: 1204692,
        sma_saiha: 1204693,
        sma_saitual: 1204698,
        sma_serchhip: 1204694,
        smc_aizawl: 1204699,
        smc_champhai: 1204706,
        smc_hnahthial: 1204707,
        smc_khawzawl: 1204709,
        smc_kolasib: 1204700,
        smc_lawngtlai: 1204701,
        smc_lunglei: 1204702,
        smc_mamit: 1204703,
        smc_saiha: 1204704,
        smc_saitual: 1204708,
        smc_serchhip: 1204705,
        susv_aizawl: 1204732,
        susv_champhai: 1204739,
        susv_hnahthial: 1204741,
        susv_khawzawl: 1204742,
        susv_kolasib: 1204733,
        susv_lawngtlai: 1204734,
        susv_lunglei: 1204735,
        susv_mamit: 1204736,
        susv_saiha: 1204738,
        susv_saitual: 1204740,
        susv_serchhip: 1204737
      },
      paginator: null,
      paginatorBack: null,
      userSearchedPending: [],
      userSearched: [],
      userSearchBool: false,
      userSearchBoolPending: false,
      lengthUser: null,
      searchBy: null,
      counter: '',
      pages: 1,
      realtest: [],
      userPending: [],
      userDistrict: null,
      userNulm: null,
      userDialog: false,
      userDialogPending: false,
      loading: false,

      tab: null,
      District: [
        'Aizawl',
        'Kolasib',
        'Lawngtlai',
        'Lunglei',
        'Mamit',
        'Saiha ',
        'Serchhip',
        'Champhai',
        'Hnahthial',
        'Khawzawl',
        'Saitual'
      ],
      slideGroup: '',
      slideGroupPending: '',
      search: '',
      searchTable: '',

      headersReports: [
        { text: 'Date of Submission', value: 'createdAt' },
        { text: 'Date of Report', value: 'dateOfSubmission' },
        { text: 'Type', value: 'reportType' },
        { text: 'Approved By', value: 'docId' }
      ],
      id: '',
      reportList: [],
      userTemp: [],
      userDepartment: null
    }
  },

  methods: {
    userButton (bool1, bool2) {
      return bool1 || !bool2
    },
    searchFilter () {
      let search = this.search
      if (
        this.searchBy === 'Name' &&
        !['Kehdarh', 'Nung Tha Mek'].includes(this.search)
      ) {
        this.search = search.toUpperCase()
      }
      if (
        this.searchBy === 'Name' &&
        ['Kehdarh', 'Nung Tha Mek'].includes(this.search)
      ) {
        this.search = ''
      }
      if (this.searchBy === 'Group Status') {
        this.search = 'Kehdarh'
      }
    },
    sendSMS (det, mode) {
      if (mode === 'Single') {
        let data = det.name.concat('-', det['phone'])
        this.$router.replace({
          name: 'SMSSender',
          params: { recipient: data }
        })
      } else {
        let data = det
        this.$router.replace({
          name: 'SMSSender',
          params: { recipient: data }
        })
      }
    },
    deleteUser (id) {
      this.progress = '0'
      let question = 'Are you sure you want to delete/reject the current User'
      if (confirm(question) == true) {
        this.userDialog = false
        if (id === this.userDetailsRaw.id) {
          this.progressDialog = true

          this.progressMessage = 'Deleting User Please wait'
          if (this.tab === 0) {
            let userData = this.userDetailsRaw
            let col, doc
            doc = userData.department ?? userData.nulm
            if (['SMMU', 'CMMU'].includes(userData.nulm)) {
              col = userData.nulm.toLowerCase()
            } else if (!['SMMU', 'CMMU'].includes(userData.nulm)) {
              col = `${userData.nulm
                .toLowerCase()
                .replace('&', 'n')}_${userData.district.toLowerCase()}`
            }
            this.progress = '35'
            this.progressMessage = 'Deleting User Documents'
            firebase
              .firestore()
              .collection(col.trim())
              .doc(doc.trim())
              .update({
                user: firebase.firestore.FieldValue.arrayRemove(userData.id)
              })
              .then(() => {
                this.progress = '50'
                this.progressMessage = 'Deleting User From NULM List'
                firebase
                  .firestore()
                  .collection(col.trim())
                  .doc(id)
                  .delete()
                  .then(async () => {
                    this.$store.dispatch('unbindUserList')
                    this.$store.dispatch('bindUserList')
                    await this.deleteFromContact()
                    this.progress = '100'
                    this.progressMessage = 'Completed'
                    setTimeout(() => {
                      this.userDialog = false
                      this.progressDialog = false
                      this.progress = '0'
                    }, 1000)
                  })
              })
          }
          if (this.tab === 1) {
            this.progress = '50'
            this.progressMessage = 'Deleting User Unapproved List'
            firebase
              .firestore()
              .collection('usersUnapproved')
              .doc(id)
              .delete()
              .then(async () => {
                await this.deleteUserFromFb(id)
                setTimeout(() => {
                  this.userDialog = false
                  this.progressDialog = false
                }, 1000)
              })
          }
        }
      }
    },
    deleteUserFromFb (id) {
      let codeForAuth
      Object.keys(this.userInfo).forEach(async e => {
        if (e.startsWith('fiem')) codeForAuth = e
      })

      var data = [id]

      var config = {
        method: 'post',
        url:
          'https://us-central1-ud-and-pa.cloudfunctions.net/widgets/deleteuser',
        headers: {
          auth: codeForAuth,
          'Content-Type': 'application/json'
        },
        data: data
      }

      axios(config)
        .then(function (response) {
          this.progress = '80'
          this.progressMessage = 'Deleting User From Authentication'

          return JSON.stringify(response.data)
        })
        .catch(function (error) {
          return error
        })
    },
    async deleteFromContact () {
      let contact = (this.userDetailsRaw.department || this.userDetailsRaw.nulm)
        .replace('&', 'n')
        .toLowerCase()
      if (!['SMMU', 'CMMU'].includes(this.userDetailsRaw.nulm)) {
        contact = contact.concat(
          '_',
          this.userDetailsRaw.district.toLowerCase()
        )
      }
      let groupId = this.groupId[contact]
      let api
      await Object.keys(this.userInfo).forEach(async e => {
        if (e.startsWith('fie')) {
          api = e
        }
      })

      const apiKey = Buffer.from(this.userInfo[api], 'base64').toString('ascii')
      var url = 'https://api.textlocal.in/delete_contact/?'
      var newest = `${url}apikey=${apiKey}&group_id=${groupId}&number=91${this.userDetailsRaw.phone}`
      await axios
        .post(newest)
        .then(res => {
          this.progress = '65'
          this.progressMessage = 'Deleting User Contact List'
          return res
        })
        .catch(function (error) {
          return error
        })
    },
    nulmCheck (input) {
      if (['SMMU', 'CMMU', 'ESTP', 'SEP', 'SUSV'].includes(input)) {
        this.userDepartment = input
      }
    },
    tsTodate (dateInput) {
      let returnee = dateInput.toDate()
      return returnee.toString().replace('GMT+0530 (India Standard Time)', '')
    },

    async searchByUser () {
      this.tempGroup = (this.userDepartment || this.userNulm)
        .replace('&', 'n')
        .toLowerCase()

      if (!['SMMU', 'CMMU'].includes(this.userNulm)) {
        this.tempGroup = this.tempGroup
          .concat('_', this.userDistrict)
          .toLowerCase()
      }
      if (!this.search) {
        this.userSearchBool = false
        localStorage.setItem('tempGroup', this.tempGroup)
        this.sendToGroup = true
      }
      this.loading = true
      this.$store.dispatch('unbindUserList')
      let collection
      if (['SMMU', 'CMMU'].includes(this.userNulm)) {
        collection = this.userNulm.toLowerCase()
      } else {
        collection = this.userNulm
          .replace('&', 'n')
          .concat('_', this.userDistrict)
          .toLowerCase()
      }
      if (this.search) {
        this.sendToGroup = false
        this.userSearched = []
        this.userSearchBool = true
        let name = this.search
        let searchBy = this.searchBy.toLowerCase()
        if (searchBy === 'group code') searchBy = 'code'
        if (searchBy === 'group status') searchBy = 'groupStatus'
        firebase
          .firestore()
          .collection(collection)
          .where(searchBy, '==', name)
          .get()
          .then(doc => {
            doc.forEach(async user => {
              this.userSearched.push(user.data())
            })
          })
          .catch(err => {
            this.$store.commit('changeError', err)
            this.loading = false
          })
      } else if (!this.search) {
        let documentPath
        if (['SUH', 'SM&ID'].includes(this.userNulm))
          documentPath = this.userDepartment
        else documentPath = this.userNulm
        localStorage.setItem('userNulmPref', collection)
        localStorage.setItem('userDeptPref', documentPath)
        await firebase
          .firestore()
          .collection(collection)
          .doc(documentPath)
          .get()
          .then(async doc => {
            if (doc.exists) {
              this.$store.commit('changeUserPagination', 1)
              this.$store.commit('changeUser', doc.data().user)
              this.lengthUser = Math.ceil(doc.data().user.length / 5)
              await this.$store.dispatch('bindUserList'), (this.pages = 1)
            }
            this.loading = false
          })
          .catch(err => {
            this.loading = false
            //this.$store.commit('changeError', err)
            err
          })
      }
      this.loading = false
    },

    next (page) {
      localStorage.setItem('userPage', page)
      this.$store.commit('changeUserPagination', page)
      this.$store.dispatch('unbindUserList')
      this.$store.dispatch('bindUserList')
    },
    displayUser (id) {
      this.userDetailsRaw = id
      this.reportList = []
      this.userTemp = []
      let userReport, userData, userDataPurified
      userDataPurified = []
      userReport = id.report
      this.attachmentList = id?.attachment

      this.reportList = userReport
      userData = id

      for (const propName in userData) {
        if (['name', 'district'].includes(propName)) {
          let data = {
            [this.beautifyWord(propName)]: ' '.concat(
              this.beautifyWord(userData[propName])
            )
          }
          data = JSON.stringify(data)
            .replace(/["'{}]/g, '')
            .trim()
          userDataPurified.push(data)
        } else if (
          [
            'email',
            'department',
            'nulm',
            'phone',
            'code',
            'fundsRecieved',
            'members',
            'dateEstablished'
          ].includes(propName)
        ) {
          let data = {
            [this.beautifyWord(propName)]: ' '.concat(userData[propName])
          }
          data = JSON.stringify(data)
            .replace(/["'{}]/g, '')
            .trim()
          userDataPurified.push(data)
        }
      }
      this.userDialog = true
      this.userTemp = this.clean(userDataPurified)

      this.userTemp.sort((a, b) => {
        if (a.startsWith('Name')) {
          return -1
        } else if (b.startsWith('Name')) {
          return 1
        } else return 0
      })

      this.id = id.id
    },

    sendNoti (id) {
      window.alert(id)
    },
    async approveUser (id) {
      let userDetails = this.userDetailsRaw
      this.progressDialog = true
      this.progress = '0'
      this.userDialog = false
      if (id === userDetails.id) {
        this.progressMessage = 'Initiating User Approval'
        let approve

        approve = this.$store.state.userUnapproved

        let approved_id = approve.filter(e => e?.id === id)
        approved_id = approved_id[0]
        let amount = localStorage.getItem('amount') ?? 0
        let col, collection
        let document
        let codeForAuth
        Object.keys(this.userInfo).forEach(async e => {
          if (e.startsWith('fiem')) codeForAuth = e
        })
        if (['SMMU', 'CMMU'].includes(approved_id.nulm)) {
          col = approved_id.nulm.toLowerCase().replace('&', 'n')
          document = approved_id.nulm.toUpperCase()
          collection = col
          approved_id[codeForAuth] = this.userInfo[codeForAuth]
        } else if (['ESTP', 'SUSV', 'SEP'].includes(approved_id.nulm)) {
          col = approved_id.nulm
            .toLowerCase()
            .replace('&', 'n')
            .concat('_', approved_id.district.toLowerCase())
          document = approved_id.nulm.toUpperCase()
          collection = col
        } else if (
          ['SHG', 'ALF', 'CLF', 'SMA', 'SMC'].includes(approved_id.department)
        ) {
          document = approved_id.department.toUpperCase()
          collection = approved_id.nulm
            .toLowerCase()
            .replace('&', 'n')
            .concat('_', approved_id.district.toLowerCase())
          col = approved_id.department
            ?.toLowerCase()
            .concat('_', approved_id.district.toLowerCase())
        }
        let customClaim = {
          smmu: 'admin',
          cmmu: 'admin',
          smnid: 'user',
          suh: 'user',
          estp: 'user',
          sep: 'user',
          susv: 'user'
        }

        let dataForClaims

        dataForClaims = {
          user: approved_id.id,
          customClaim:
            customClaim[approved_id.nulm.toLowerCase().replace('&', 'n')]
        }

        var config = {
          method: 'post',
          url:
            'https://us-central1-ud-and-pa.cloudfunctions.net/widgets/setCustomClaims',
          headers: {
            auth: codeForAuth,
            'Content-Type': 'application/json'
          },
          data: dataForClaims
        }

        await axios(config)
          .then(() => {
            this.progress = '15'
            this.progressMessage = "Setting User's Permission"
          })
          .catch(error => {
            this.$store.commit('changeError', error)
          })

        let data = {
          date: firebase.firestore.Timestamp.now(),
          name: 'User Approved'
        }
        let timerLiner = approved_id.timeLineItem
        if (timerLiner) {
          timerLiner = timerLiner.push(data)
        }

        let contact = [
          {
            firstName: approved_id.name,
            lastName: approved_id.id,
            number: parseInt(`91${approved_id.phone}`),
            custom1: col,
            custom2: approved_id.email
          }
        ]

        await this.createContact(contact, this.groupId[col], codeForAuth)
        await firebase
          .firestore()
          .collection(collection)
          .doc(id)
          .set(approved_id)
          .then(async () => {
            this.progress = '20'
            this.progressMessage = 'Creating User Documents'
            await firebase
              .firestore()
              .collection(collection)
              .doc(document)
              .update({
                user: firebase.firestore.FieldValue.arrayUnion(id)
              })
              .then(
                (this.progress = '50'),
                (this.progressMessage = 'Indexing User')
              )
              .catch(err => {
                if (err.code === 'not-found') {
                  firebase
                    .firestore()
                    .collection(collection)
                    .doc(document)
                    .set({
                      user: [id]
                    })
                    .then(
                      (this.progress = '50'),
                      (this.progressMessage = 'Indexing User')
                    )
                }
              })
              .finally(async () => {
                await firebase
                  .firestore()
                  .collection('usersUnapproved')
                  .doc(id)
                  .delete()
                  .then(async () => {
                    this.progress = '75'
                    this.progressMessage = 'Almost Done...'
                    await this.notifyApproval(
                      codeForAuth,
                      approved_id.phone,
                      approved_id.name,
                      approved_id.nulm
                    )
                    if (approved_id.grievance) {
                      await this.createNotification(
                        approved_id.district,
                        approved_id.grievance[0],
                        approved_id.name,
                        approved_id.nulm,
                        approved_id
                      )
                    }
                    this.progress = '100'
                    this.progressMessage = 'Completed'
                    localStorage.setItem('approved', amount + 1)
                  })
              })
          })

        this.progressDialog = false
      }
    },
    async createContact (input, gId, auth) {
      input = input[0]
      let group_id = gId
      const apiKey = Buffer.from(this.userInfo[auth], 'base64').toString(
        'ascii'
      )
      let contacts = [
        {
          number: input.number,
          first_name: input.firstName,
          last_name: input.lastName,
          custom1: input.custom1,
          custom2: input.custom2,
          custom3: input.custom3
        }
      ]

      let url = 'https://api.textlocal.in/create_contacts_bulk/?'
      let newest = `${url}apiKey=${apiKey}&contacts=${JSON.stringify(
        contacts
      )}&group_id=${group_id}`

      {
        await axios
          .get(newest)
          .then(async response => {
            if (response) {
              this.progress = '30'
              this.progressMessage = "Adding User's contact to Group Contact"
            }
          })
          .catch(error => {
            this.$store.commit('changeError', error)
          })
      }
    },
    async notifyApproval (auth, number, name, nulm) {
      let numberParse = `91${number}`
      let numberFiltered = [numberParse]
      const apiKey = Buffer.from(this.userInfo[auth], 'base64').toString(
        'ascii'
      )
      const message = `UD&PA atanga thuchhuah: I account Name:${name} Nulm:${nulm} approve a ni tawh e.`
      var url = 'https://api.textlocal.in/send/?'
      var newest = `${url}apiKey=${apiKey}&numbers=${numberFiltered.toString()}&sender=UDnPA&message=${encodeURIComponent(
        message
      )}`
      axios.post(newest).then(response => {
        if (response) {
          if (response.data.errors) this.$store = response.data.errors
          else {
            this.progress = '90'
            this.progressMessage = 'Approval SMS Sent'
          }
        }
      })
    },
    createNotification (district, data, userName, userNulm, rawData) {
      let finalData = {
        createdAt: rawData.created,
        senderId: rawData.id,
        senderNumber: rawData.phone,
        senderName: rawData.name,
        senderNulm: rawData.nulm,
        title: `Grievances from Name:${rawData.name} Nulm:${rawData.nulm}`,
        userPath: `${rawData.nulm.toLowerCase().replace('&', 'n')}_${district}`,
        msg: JSON.stringify(data)
      }
      firebase
        .firestore()
        .collection('notifications')
        .add(finalData)
        .then(doc => {
          firebase
            .firestore()
            .collection('notifications')
            .doc(`cmmu_${district.toLowerCase()}`)
            .update({
              noti: firebase.firestore.FieldValue.arrayUnion({
                body: data,
                createdAt: firebase.firestore.Timestamp.now(),
                id: doc.id,
                noti: `Grievances From ${userName} ${userNulm}`
              })
            })
            .catch(err => {
              if (String(err.message).includes('No document to update')) {
                firebase
                  .firestore()
                  .collection('notifications')
                  .doc(`cmmu_${district.toLowerCase()}`)
                  .set({
                    noti: [
                      {
                        body: data,
                        createdAt: firebase.firestore.Timestamp.now(),
                        id: doc.id,
                        noti: `Grievances From ${this.userInfo.name} ${this.userInfo.nulm}`
                      }
                    ]
                  })
              }
            })
        })
    },
    beautifyWord (valueWord) {
      if (valueWord != undefined) {
        let value = valueWord
          .toString()
          .trim()
          .split(' ')
        let final = ''
        for (let i = 0; i < value.length; i++) {
          let valueTemp = value[i]

          if (i < value.length) {
            final =
              final +
              valueTemp[0].toUpperCase() +
              valueTemp.substring(1).toLowerCase() +
              ' '
          } else {
            final =
              final +
              valueTemp[0].toUpperCase() +
              valueTemp.substring(1).toLowerCase()
          }
        }
        return final
      }
    },
    clean (obj) {
      for (var propName in obj) {
        if (
          obj[propName] === null ||
          obj[propName] === undefined ||
          obj[propName] === ''
        ) {
          delete obj[propName]
        }
      }
      return obj
    }
  },

  watch: {
    userNulm () {
  
      this.userDepartment = ''
    }
  },
  computed: {
    ...mapState(['user', 'userUnapproved', 'userList', 'userInfo']),
    headers () {
      let tempGroup = this.tempGroup ?? localStorage.getItem('tempGroup')
      let check
      if (tempGroup) check = tempGroup.split('_')[0] ?? ''
      else if (!tempGroup) check = ''
      if (['shg', 'alf', 'clf'].includes(check)) {
        return [
          {
            text: 'Name',
            align: 'start',
            value: 'name'
          },
          {
            text: 'NULM Category',
            align: 'start',
            value: 'nulm'
          },
          {
            text: 'Department',
            align: 'start',
            value: 'department'
          },
          {
            text: 'District',
            align: 'start',
            value: 'district'
          },
          {
            text: 'Group Code',
            align: 'start',
            value: 'code'
          },

          {
            text: 'Group Status',
            align: 'start',
            value: 'groupStatus'
          }
        ]
      }
      return [
        {
          text: 'Name',
          align: 'start',
          value: 'name'
        },
        {
          text: 'NULM Category',
          align: 'start',
          value: 'nulm'
        },
        {
          text: 'Department',
          align: 'start',
          value: 'department'
        },
        {
          text: 'District',
          align: 'start',
          value: 'district'
        },
        {
          text: 'Group Code',
          align: 'start',
          value: 'code'
        },
        {
          text: 'Date of establishment',
          align: 'start',
          value: 'dateEstablished'
        }
      ]
    },
    floatBtnControl () {
      return (
        this.userListFinal.length > 0 &&
        ['SMMU', 'CMMU'].includes(this.userInfo.nulm) &&
        this.sendToGroup
      )
    },
    deptCheck () {
      if (['SM&ID', 'SUH'].includes(this.userNulm)) {
        return true
      } else return false
    },
    NULM () {
      let nulm = this.userInfo.nulm ?? ''
      if (nulm === 'SMMU') {
        return ['SMMU', 'CMMU', 'SM&ID', 'SUH', 'ESTP', 'SEP', 'SUSV']
      } else if (nulm === 'CMMU') {
        return ['CMMU', 'SM&ID', 'SUH', 'ESTP', 'SEP', 'SUSV']
      } else return ['']
    },
    userType () {
      if (this.userNulm === 'SM&ID') {
        if (this.userInfo.department === 'ALF') {
          return ['SHG']
        }
        if (this.userInfo.department === 'CLF') {
          return ['SHG', 'ALF']
        } else return ['SHG', 'ALF', 'CLF']
      } else if (this.userNulm === 'SUH') {
        if (this.userInfo.department === 'SMC') {
          return ['SMA']
        } else return ['SMA', 'SMC']
      } else if (this.userNulm === 'SEP') {
        return ['SEP']
      } else if (this.userNulm === 'SUSV') {
        return ['SUSV']
      } else if (this.userNulm === 'ESTP') {
        return ['ESTP']
      } else return ['']
    },
    userListFinal () {
      if (this.userSearchBool == false) {
        return this.userList
      } else {
        return this.userSearched
      }
    },
    userListFinalPending () {
      if (this.userSearchBoolPending == false) {
        return this.userUnapproved
      } else {
        return this.userSearchedPending
      }
    },
    totalUser () {
      if (this.userSearchBool) {
        return this.userListFinal
      }
      //userListFinalPending
      else {
        return this.$store.state.user
      }
    }
  },
  async created () {
    if (!['SMMU', 'CMMU'].includes(this.userInfo.nulm)) {
      this.userNulm = this.userInfo.nulm
      this.userDistrict = this.userInfo.district
    }
    this.$store.dispatch('bindUserUnapproveList')
    if (this.$store.state.userInfo.nulm === 'CMMU') {
      this.userDistrict = this.$store.state.userInfo.district
    }

    switch (this.userInfo.department) {
      case 'ALF':
        this.userDepartment = 'SHG'
        break
      case 'CLF':
        this.userDepartment = 'ALF'
        break
      case 'SMC':
        this.userDepartment = 'SMA'
        break
      default:
        this.userDepartment = ''
    }
  },
  async mounted () {
    // this.loading = true;

    if (this.$store.state.user.length > 0) {
      let User = this.$store.state.user
      this.tempGroup = localStorage.getItem('tempGroup')

      this.lengthUser = Math.ceil(User.length / 5)
      this.pages = parseInt(localStorage.getItem('userPage'))
    }

    this.loading = false
  },
  beforeRouteEnter (to, from, next) {
    next()
  },
  beforeRouteLeave (to, from, next) {
    next()
  }
}
</script>
<style lang="css" scoped>
.tableData {
  box-sizing: border-box;
  border: 1px solid rgba(128, 128, 128, 0.37);
  border-radius: 2px;
}
.row-pointer >>> tbody tr :hover {
  cursor: pointer;
}
.bgSvg {
  min-height: 100vh;

  background-color: var(--v-secondary-darken--20);
  background-repeat: repeat;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='111' height='111' viewBox='0 0 200 200'%3E%3Cdefs%3E%3ClinearGradient id='a' gradientUnits='userSpaceOnUse' x1='100' y1='33' x2='100' y2='-3'%3E%3Cstop offset='0' stop-color='%23000' stop-opacity='0'/%3E%3Cstop offset='1' stop-color='%23000' stop-opacity='1'/%3E%3C/linearGradient%3E%3ClinearGradient id='b' gradientUnits='userSpaceOnUse' x1='100' y1='135' x2='100' y2='97'%3E%3Cstop offset='0' stop-color='%23000' stop-opacity='0'/%3E%3Cstop offset='1' stop-color='%23000' stop-opacity='1'/%3E%3C/linearGradient%3E%3C/defs%3E%3Cg fill='%23cacaca' fill-opacity='0.46'%3E%3Crect x='100' width='100' height='100'/%3E%3Crect y='100' width='100' height='100'/%3E%3C/g%3E%3Cg fill-opacity='0.46'%3E%3Cpolygon fill='url(%23a)' points='100 30 0 0 200 0'/%3E%3Cpolygon fill='url(%23b)' points='100 100 0 130 0 100 200 100 200 130'/%3E%3C/g%3E%3C/svg%3E");
}
.bgSvgDark {
  min-height: 100vh;

  background-color: #07070748;
  background-repeat: repeat;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100' height='100' viewBox='0 0 200 200'%3E%3Cdefs%3E%3ClinearGradient id='a' gradientUnits='userSpaceOnUse' x1='100' y1='33' x2='100' y2='-3'%3E%3Cstop offset='0' stop-color='%23000' stop-opacity='0'/%3E%3Cstop offset='1' stop-color='%23000' stop-opacity='1'/%3E%3C/linearGradient%3E%3ClinearGradient id='b' gradientUnits='userSpaceOnUse' x1='100' y1='135' x2='100' y2='97'%3E%3Cstop offset='0' stop-color='%23000' stop-opacity='0'/%3E%3Cstop offset='1' stop-color='%23000' stop-opacity='1'/%3E%3C/linearGradient%3E%3C/defs%3E%3Cg fill='%23060606' %3E%3Crect x='100' width='100' height='100'/%3E%3Crect y='100' width='100' height='100'/%3E%3C/g%3E%3Cg fill-opacity='1'%3E%3Cpolygon fill='url(%23a)' points='100 30 0 0 200 0'/%3E%3Cpolygon fill='url(%23b)' points='100 100 0 130 0 100 200 100 200 130'/%3E%3C/g%3E%3C/svg%3E");
}
.reportCard {
  min-height: 100vh;
}
.backDropper {
  min-height: 100vh;

  backdrop-filter: blur(15px);
}
.buttonGlass {
  box-sizing: border-box;
  overflow: hidden;
}
.buttonGlass::before {
  position: absolute;
  left: 0%;
  width: 20%;
  backdrop-filter: blur(2px);
  transform: skewX(-40deg) translateX(-3rem);
  background-color: white;
  opacity: 0.4 !important;
  z-index: 2;
  transition: 0.4s ease-out;
  border-radius: none !important;
}
.buttonGlass:hover::before {
  left: 100%;
  transform: skewX(-40deg) translateX(1rem);
}
.divider-con::before {
  content: '';
  background-color: red !important;
  position: absolute !important;
  top: 100% !important;
  width: 20% !important;
  height: 20% !important;
  z-index: 4;
}
</style>
